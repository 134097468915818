import { IStarterAppConfiguration } from "../configuration";
import { BrowserCacheLocation, InteractionType } from "@azure/msal-browser";

export const T_ENV: IStarterAppConfiguration = {
  apiUrl: "https://api-incident-t.bkw.ch",
  azureConfig: {
    auth: {
      clientId: "5580c241-16cb-47ea-a810-1bda01eb064a",
      authority: "https://login.microsoftonline.com/bkwfmbenergie.onmicrosoft.com",
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
    },
  },
  msalAngularInterceptorConfig: {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: [
      [
        "https://api-incident-t.bkw.ch/*",
        ["api://3581933b-9146-47bc-ab74-667c15d8bcdd/user_impersonation"],
      ],
    ] as any,
  },
  msalAngularGuardConfig: {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [],
    },
    loginFailedRoute: "./unauthorized",
  },
};
