
<div class="bfc-margin-bottom-2">
    <h3>{{"DASHBOARD.WELCOME_TEXT" | bfcTranslate}}</h3>
    <div class="bfc-margin-top-3">{{"DASHBOARD.INFO_TEXT" | bfcTranslate}}</div>
    <div class="bfc-margin-top-2">{{"DASHBOARD.INFO_TEXT_2" | bfcTranslate}}</div>
    <div>{{"DASHBOARD.INFO_TEXT_3" | bfcTranslate}}</div>
    <div class="bfc-margin-top-2">{{"DASHBOARD.SIGNATURE" | bfcTranslate}}</div>
</div>

<div class="card bfc-margin-top-2" routerLink="/accidentReport">
    <img src="assets/accident.png" alt="Avatar" class="card-image">
    <div class="container">
        <span><b>{{"BUTTONS.ACCIDENT_REPORT" | bfcTranslate}}</b></span>
    </div>
</div>