export const DE_TRANSLATIONS = {
  REPORT_TITLE: "Arbeitsschutz & Gesundheitsschutz",
  DONE_STEP: "Fertig",
  BACK_TO_DASHBOARD: "Zurück zur Einstiegsseite",
  SUCCESS_MESSAGE: "Die Daten wurden erfolgreich gespeichert!",
  REPORT_CREATED_TEXT_1: "Vielen Dank für die Erfassung der Daten!",
  REPORT_CREATED_TEXT_2: "Diese sind nun gespeichert und werden von der Fachstelle AS & GS ausgewertet.",
  REPORT_CREATED_TEXT_3: " Für Fragen senden Sie uns bitte eine Mail auf die folgende Adresse: <a href='mailto:asgs@bkw.ch'>asgs@bkw.ch</a>",
  DASHBOARD: {
    TITLE: "Erfassung von Arbeitsunfällen",
    WELCOME_TEXT: "Herzlich Willkommen zur Erfassung von Arbeitsunfällen.",
    INFO_TEXT: "Zur Verbesserung der Arbeitssicherheit ist es zwingend erforderlich, die aktuelle Situation in der BKW Gruppe zu verstehen, um daraus Massnahmen abzuleiten. Ein wichtiges Mittel zur Analyse der aktuellen Situation sind Kennzahlen. Diese Daten werden verwendet, um kontinuierlich an der Verbesserung von Gesundheits- bzw. Sicherheitsstandards und -verfahren sowie neuen Zielen zu arbeiten.",
    INFO_TEXT_2: "Mit der Erfassung der Daten tragen Sie einen wichtigen Beitrag an unserer Sicherheitskultur bei.",
    INFO_TEXT_3: " Aus Erfahrungen können wir lernen und Verbesserungen implementieren. Vielen Dank",
    SIGNATURE: "Fachstelle Arbeitssicherheit & Gesundheitsschutz",
  },
  BUTTONS: {
    NEXT: "Weiter",
    BACK: "Zurück",
    SAVE: "Speichern",
    CLOSE: "Schliessen",
    CANCEL: "Abbrechen",
    ACCIDENT_REPORT: "Arbeitsunfall melden",
  },
  BASEDATA: {
    TITLE: "Grunddaten",
    FIRST_NAME: "Name",
    LAST_NAME: "Vorname",
    BIRTH_DATE: "Geburtsdatum",
    ACCIDENT_DATE: "Schadensdatum",
    ACCIDENT_LOCATION: "Unfallort",
    ACCIDENT_LOCATION_HINT: "nur Ortsangabe ohne Adresse.",
    ORGANISATION_HINT: "Geben Sie die Kompetenzfelder und das Land ein, um dieses Feld zu aktivieren",
    COUNTRY: "Land",
    REPORTER_INFO: "Daten erfasst von:",
    RADIO_BUTTON_TEXT: "Um was für eine Meldung handelt es sich?",
    COMPETENCES: "Kompetenzfelder",
    ENERGY: "Energy",
    POWERGRID: "Power Grid",
    INFRASERVICE: "Infra-Services",
    BUILDING_SOLUTION: "Building Solutions",
    ENGINEERING: "Engineering",
    ENERGY_PRODUCTIONS: "Energy Productions",
    ENERGY_MARKETS: "Energy Markets",
    GROUP_SERVICES: "Group Services",
    DOUBLED_REPORT_INFO: "Achtung: Diese Daten wurden bereits erfasst. Bitte kontrollieren.",
    INFO_TEXT_TYPE_3: ` <span> Definition gemäss Deutsche Gesetzliche Unfallversicherung (DGUV):</span>
                            <div>Wegeunfälle sind Unfälle, die Beschäftigte auf dem Weg zur oder von der Arbeit erleiden. Versichert sind auch Umwege, die zum Beispiel nötig werden:</div>
                            <ul>
                            <li>um Kinder während der Arbeitszeit unterzubringen</li>
                            <li>bei Fahrgemeinschaften</li>
                            <li>bei Umleitungen</li>
                            <li>weil der Arbeitsplatz über einen längeren Weg schneller erreicht werden kann</li>
                            </ul>
                             <div>Definition gemäss GRI Standard 403:</div>
                             <div>Unfälle während des Pendelns zur Arbeitsstelle nur wenn die Beförderung von der Organisation organisiert wurde.</div>`,
    OCCUPATIONAL_ACCIDENT_LOST_DAYS: "Arbeitsunfall grösser 1 Ausfalltag",
    DEADLY_OCCUPATIONAL_ACCIDENT: "Tödllicher Arbeitsunfall",
    ACCIDENT_ON_THE_WAY_TO_WORK: "Wegeunfälle",
    OCCUPATIONAL_ACCIDENT_WITHOUT_LOST_DAYS: "Arbeitsunfall ohne Ausfalltage",
    NON_OCCUPATIONAL_ACCIDENT: "Nicht-Berufsunfall",
    PROPERTY_DAMAGE: "Sachschaden",
    NEAR_ACCIDENT: "Beinahe-Unfall",
    TYPE_10: "Erste-Hilfe ohne Ausfallzeit",
    EMAIL: "Email",
    TYPE_OF_PERSON: {
      LABEL: "Bei der verletzten Person handelt es sich um:",
      INTERNAL_STAFF: "Eigenpersonal",
      EXTERNAL_STAFF: "Fremdpersonal",
    },
    COUNTRIES: {
      DE: "Deutschland",
      CH: "Schweiz",
      AT: "Österreich",
      FR: "Frankreich",
      NO: "Norwegen",
      IT: "Italien",
      HR: "Kroatien",
      SG: "Singapur",
    },

  },
  ABSENCE_TIME: {
    TITLE: "Fehlzeit",
    ABSENCE_START: "Ausfall von Datum",
    ABSENCE_END: "Ausfall bis Datum",
    MISSED_WORKING_HOURS: "Ausgefallene Arbeitsstunden",
    EMAIL: "Email",
  },
  ACCIDENT_CAUSE: {
    TITLE: "Unfallkategorie",
    INJURY_TYPE: "Art der Verletzungen",
    DESCRIPTION: "Beschreibung",
    BODYPARTS_TITLE: "Verletzte Körperteile: ",
    CHOSEN_BODYPARTS: "Auswahl der Körperteile:",
    TOOL_DEFECT: "Werkzeuge / Geräte / elektrische Maschinen, wenn in der Hand gehalten",
    LOAD_MOVEMENT_BY_HAND: "Lastenbewegung von Hand, (herab-/-um-) fallende Gegenstände",
    SHARP_OBJECTS: "spitzige, schneidende Gegenstände, Splitter",
    EYE_INJURIES: "Augenverletzungen, wenn Augapfel betroffen, unabhängig von Ursache",
    HARMFUL_SUBSTANCES: "Gesundheitsschädigende Stoffe und Einwirkungen",
    FALLS: "Fehltritte, Stürze",
    MACHINE_USE: "Arbeitsmaschinen, wenn fest montiert / elektrische Anlagen",
    MECHANICAL_TRANSPORT: "Mechanische Transportmittel (mit/ohne Lasten), fahrbare Geräte, Strassenverkehr",
    OTHER_CAUSE: "Übrige Ursachen",
    BITE: "Biss",
    FRACTURES: "Bruch",
    INFLAMMATION: "Entzündung",
    FOREIGN_BODY: "Fremdkörper",
    PROSPECTING: "Schürfung",
    BRUISE: "Prellung",
    CRACK: "Riss",
    CUT: "Schnitt",
    GRAZING_SHOT: "Schuss",
    SWELLING: "Schwellung",
    COMPRESSION_SPINE: "Stauchung Wirbelsäule",
    STING: "Stich",
    SEPARATION: "Trennung, Abtrennung",
    CHEMICAL_BURN: "Verätzung",
    COMBUSTION: "Verbrennung",
    SPRAIN: "Verdrehung, Verstauchung",
    POISONING: "Vergiftung",
    CONTORTION: "Verrenkung",
    STRAIN: "Zerrung",
    OTHER_DAMAGE: "Andere Schädigung",
    FIRST_PRIORITY: "Hauptverletzung",
    SECOND_PRIORITY: "2. Verletzung",
    BODYPART_GROUP_LABEL: "Betroffene Körperregion",
    BODYPARTS: {
      OTHER_INTERNAL_INJURY: "Andere innere Verletzungen",
      EYE: "Auge",
      ABDOMEN: "Bauch",
      PELVIS: "Becken",
      BLADDER: "Blase",
      THORACIC_SPINE: "Brustwirbelsäule",
      ELBOW: "Ellbogen",
      FINGER: "Finger",
      ANKLE: "Fussgelenk",
      WHOLE_BODY: "Ganzer Körper (systemische Wirkung)",
      GENITALS: "Genitalien",
      FACE: "Gesicht",
      NECK: "Hals",
      CERVICAL_SPINE: "Halswirbelsäule",
      WRIST: "Handgelenk",
      HEART: "Herz",
      HIP_JOINT: "Hüftgelenk",
      JAW: "Kiefer",
      KNEE: "Knie",
      LIVER: "Leber",
      GROIN: "Leiste",
      LUMBAR_SPINE: "Lendenwirbelsäule",
      LUNG: "Lunge (inkl. Atemwege)",
      AREAS_UPPER_EXTREMITIES: "Mehrere Bereiche der oberen Extremitäten",
      AREAS_LOVER_EXTREMITIES: "Mehrere Bereiche der unteren Extremitäten",
      MULTIPLE_INJURIES: "Mehrfachverletzung (Polyblesse)",
      SPLEEN: "Milz",
      METATARSUS: "Mittelfuss",
      METACARPUS: "Mittelhand (ohne Finger)",
      NOSE: "Nase",
      KIDNEY: "Niere",
      UPPER_ARM: "Oberarm",
      THIGH: "Oberschenkel",
      EAR_HEARING: "Ohr (Gehör)",
      BACK: "Rücken",
      SKULL_BRAIN: "Schädel / Hirn",
      SHOCK: "Schock (Psyche)",
      SHOULDER: "Schulter",
      COCCYX_BUTTOCKS: "Steissbein (Gesäss)",
      THORAX_RIBS_RIB_CAGE: "Thorax (Rippen, Brustkorb)",
      FOREARM: "Unterarm",
      LOWER_LEG: "Unterschenkel",
      TEETH: "Zähne",
      TOES: "Zehen",
      GROUP1: "Becken, Hüfte, Beine (ohne Knie, Fuss)",
      GROUP2: "Finger, Hand, Handgelenk",
      GROUP3: "Haut, innere Organe",
      GROUP4: "Knie, Meniskus",
      GROUP5: "Kopf (inkl. Augen, Gehör, Zähne, Hals)",
      GROUP6: "Rücken, Wirbelsäule",
      GROUP7: "Rumpf, Mehrfachverletzungen, übrige",
      GROUP8: "Schulter, Arme (ohne Hand, Handgelenk)",
      GROUP9: "Sprunggelenk, Fuss,  Zehen",
    },
    BODYPART_DESCRIPTION: "Beschreibung",
    OTHER_DESCRIPTION_TEXT:"Ausführliche Schilderung des Unfallhergangs (Verlauf, Beteilung von Maschinen, Anlagen, Gefahrstoffen)",
    OTHER_DESCRIPTION_TITLE:"Ausführliche Schilderung",
    SIDE: "Seite",
    LEFT: "Links",
    RIGHT: "Rechts",
    BOTH: "Mitte / Beide",
    UNDETERMINED: "Unbestimmt",
  },
  VALIDATIONS: {
    REQUIRED_FIELD: "Dieses Feld ist erforderlich!",
  },
  FIRMA: "Firma",
  INFO_DIALOG: {
    TITLE: "Codiert wird die Unfallursache, die am Anfang einer Verkettung von Ursachen steht (d.h. die erste auslösende Unfallursache).",
    FIRST_OPTION: {
      TITLE:"1 Werkzeuge/Geräte / elektrische Maschinen, wenn in der Hand gehalten",
      LIST_ITEM_1: "Schnittwunden/Anschlagen/Einklemmen durch Werkzeuge/Geräte auch bei",
      LIST_ITEM_2: "Montage/Demontage/Isolationsarbeiten/Schalungsarbeiten",
      LIST_ITEM_3: "Schnittwunden durch Werkstück = Code 3",
      LIST_ITEM_4: "beim Arbeiten fallender Hammer",
      LIST_ITEM_5: "Überanstrengung der Hände/Arme/Schultern bei Montage oder anderen repetitiven Arbeiten mit Werkzeugen",

    },
    SECOND_OPTION: {
      TITLE:"2 Lastenbewegung von Hand, (herab-/-um-)fallende Gegenstände",
      LIST_ITEM_1: "inkl. Überanstrengung von Händen/Armen/Schultern/Rücken bei Lastenbewegungen",
      LIST_ITEM_2: "Anschlagen/Einklemmen bei Ein-/Umräumarbeiten",
      LIST_ITEM_3: "bei Montage/Schalungsarbeiten nicht durch Werkzeug verletzt",
      LIST_ITEM_4: "Schnitt-/Stichwunden durch Nägel, Armierungseisen etc. = Code 3",
    },
    THIRD_OPTION: {
      TITLE:"3 spitzige, schneidende Gegenstände, Splitter",
      LIST_ITEM_1: "ohne Schnittwunde/Splitter durch Handwerkzeug = Code 1 oder Arbeitsmaschine = Code 7",
      LIST_ITEM_2: "mit Fuss in Nagel treten (nicht Code 6)",
    },
    FOURTH_OPTION: {
      TITLE:"4 Augenverletzungen, wenn Augapfel betroffen, unabhängig von Ursache",
    },
    FIFTH_OPTION: {
      TITLE:"5 Gesundheitsschädigende Stoffe und Einwirkungen",
      LIST_ITEM_1: "Säuren, Laugen, Gifte, Quarz/Silikose, Asbest, Mehl; Asthma durch Labortiere etc.",
      LIST_ITEM_2: "Ekzem-verursachende Stoffe, Krankheitserreger (Amöben, Malaria etc.)",
      LIST_ITEM_3: "physikalische Einwirkungen (Kälte, Verbrennungen, Lärm, Vibrationen, Explosionen, Ertrinken)",
      LIST_ITEM_4: "chronischer Druck auf Nerven oder Gelenke, z.B. Schleimbeutelentzündungen vor der Kniescheibe bei\n" +
        "                                Plattenleger",
    },
    SIXTH_OPTION: {
      TITLE:"6 Fehltritte, Stürze",
      LIST_ITEM_1: "inkl. Ausrutschen, Anstossen der Zehen beim Gehen; Verletzungen bei Sprüngen",
      LIST_ITEM_2: "Fuss/Knie verdreht ohne Sturz",
      LIST_ITEM_3: "Sturz bei Epilepsie oder duch andere Krankheiten",
      LIST_ITEM_4: "Sturz von stehenden Fahrzeugen, Maschinen, Leitern oder Gerüsten etc.",
      LIST_ITEM_5: "Verletzungen durch rettenden Griff",
    },
    SEVENTH_OPTION: {
      TITLE:"7 Arbeitsmaschinen, wenn fest montiert / elektrische Anlagen",
      LIST_ITEM_1: "inkl. Schneiden/Anschlagen/Anstossen/Einklemmen bei Arbeit an Maschinen",
      LIST_ITEM_2: "Splitter/wegfliegende Teile von Maschinen, unter Druck stehende Schläuche, Druckluft",
      LIST_ITEM_3: "Verletzungen durch Schweissen, Abbrennen, Stromschlag",
    },
    EIGHT_OPTION: {
      TITLE:"8 Mechanische Transportmittel (mit/ohne Lasten), fahrbare Geräte, Strassenverkehr",
      LIST_ITEM_1: "inkl. Anschlagen/Anstossen/Einklemmen mit Handwagen, Staplern, Lastwagen, Kranen, Liften, Lifttüren,\n" +
        "                                Förderbändern, Förderanlagen, Baggern, Walzen",
      LIST_ITEM_2: "Lastabsturz von vorgenannten Geräten",
      LIST_ITEM_3: "Verkehrsunfälle inkl. alle Unfälle mit Zweiradfahrzeugen, auch auf Werkareal",
    },
    NINTH_OPTION: {
      TITLE:"9 Übrige Ursachen",
      LIST_ITEM_1: "Anschlagen/Anstossen ohne spezielle Tätigkeit",
      LIST_ITEM_2: "Unfug, Streitereien, Diebe, Selbstmord",
      LIST_ITEM_3: "Verletzungen durch Tiere/Insekten",
      LIST_ITEM_4: "Sport (inkl. Verletzungen in Garderobe, Dusche etc.)",
      LIST_ITEM_5: "alle Unfälle in der Berufsschule",
      LIST_ITEM_6: "übrige nicht zu 1 bis 8 zuteilbare Unfälle",
    },
  },
};
