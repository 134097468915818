export const FR_TRANSLATIONS = {
  REPORT_TITLE: "Sécurité au travail et protection de la santé",
  DONE_STEP: "Terminé",
  BACK_TO_DASHBOARD: "Retour à la page d’accueil",
  SUCCESS_MESSAGE: "Les données ont été enregistrées avec succès",
  REPORT_CREATED_TEXT_1: "Merci d’avoir saisi vos données!",
  REPORT_CREATED_TEXT_2: "Celles-ci sont maintenant enregistrées et vont être évaluées par le service spécialisé ST & PS.",
  REPORT_CREATED_TEXT_3: " Pour toute question, veuillez nous envoyer un e-mail à l’adresse suivante: <a href='mailto:asgs@bkw.ch'>asgs@bkw.ch</a>",
  DASHBOARD: {
    TITLE: "Enregistrement des accidents du travail",
    WELCOME_TEXT: "Bienvenue dans le service de saisie des accidents du travail.",
    INFO_TEXT: "Afin d’améliorer la sécurité au travail, il est impératif de comprendre la situation actuelle au sein du groupe BKW afin d’en déduire des mesures à prendre. Les chiffres-clés constituent un outil important pour analyser la situation actuelle. Ces données sont utilisées pour travailler en permanence à l’amélioration des normes et des procédures en matière de santé et de sécurité, ainsi qu’à la définition de nouveaux objectifs.",
    INFO_TEXT_2: "En saisissant vos données, vous apportez une contribution importante à notre culture de la sécurité.",
    INFO_TEXT_3: " Nous pouvons apprendre de nos expériences et mettre en œuvre des améliorations. Merci beaucoup",
    SIGNATURE: "Le service spécialisé pour la Sécurité au travail et protection de la santé",
  },
  BUTTONS: {
    NEXT: "Suivant",
    BACK: "Retour",
    SAVE: "Enregistrer",
    CLOSE: "Terminé",
    CANCEL: "Annuler",
    ACCIDENT_REPORT: "Signaler un accident du travail",
  },
  BASEDATA: {
    TITLE: "Données de base",
    FIRST_NAME: "Prénom",
    LAST_NAME: "Nom",
    BIRTH_DATE: "Date de naissance",
    ACCIDENT_DATE: "Date de l'accident",
    ACCIDENT_LOCATION: "Lieu de l'accident",
    ACCIDENT_LOCATION_HINT: "Uniquement indication de la localité sans adresse",
    ORGANISATION_HINT: "Saisissez les domaines de compétence et le pays pour activer ce champ",
    COUNTRY: "Pays",
    REPORTER_INFO: "Données collectées depuis:",
    RADIO_BUTTON_TEXT: "De quel type de signalement s’agit-il?",
    COMPETENCES: "Champs de compétences",
    ENERGY: "Energy",
    POWERGRID: "Power Grid",
    INFRASERVICE: "Infra-Services",
    BUILDING_SOLUTION: "Building Solutions",
    ENGINEERING: "Engineering",
    ENERGY_PRODUCTIONS: "Energy Productions",
    ENERGY_MARKETS: "Energy Markets",
    GROUP_SERVICES: "Group Services",
    DOUBLED_REPORT_INFO: "Attention : Ces données ont déjà été enregistrées. Vérifiez s'il vous plaît.",
    INFO_TEXT_TYPE_3: ` <span> Définition selon l’Assurance sociale allemande contre les accidents (DGUV):</span>
                            <div>Les accidents de trajet sont des accidents subis par les salariés sur le che-min jusqu’au travail ou depuis le travail. </div>
                            <div>Sont également couverts les détours qui peuvent s’avérer nécessaires, par exemple:</div>
                            <ul>
                            <li>pour accueillir des enfants pendant le temps de travail</li>
                            <li>en cas de covoiturage</li>
                            <li>en cas de déviations</li>
                            <li>si le lieu de travail peut être atteint plus rapidement en empruntant un plus long chemin</li>
                            </ul>
                             <div>Définition selon la norme GRI 403:
                             <div>Accidents survenus pendant les allers-retours jusqu’au lieu de travail uniquement si le transport a été organisé par l’organisation. </div>
</div>`,
    OCCUPATIONAL_ACCIDENT_LOST_DAYS: "Accident du travail avec plus de 1 jour d’arrêt",
    DEADLY_OCCUPATIONAL_ACCIDENT: "Accident du travail mortel",
    ACCIDENT_ON_THE_WAY_TO_WORK: "Accidents de trajet",
    OCCUPATIONAL_ACCIDENT_WITHOUT_LOST_DAYS: "Accident du travail sans jours d’arrêt",
    NON_OCCUPATIONAL_ACCIDENT: "Accident non professionnel",
    PROPERTY_DAMAGE: "Dommages matériels",
    NEAR_ACCIDENT: "Quasi-accident",
    TYPE_10: "Premiers secours sans arrêt",
    EMAIL: "E-mail",
    TYPE_OF_PERSON: {
      LABEL: "La personne blessée fait partie:",
      INTERNAL_STAFF: "du personnel interne",
      EXTERNAL_STAFF: "du personnel externe",
    },
    COUNTRIES: {
      DE: "Allemagne",
      AT: "Autriche",
      FR: "France",
      NO: "Norvège",
      IT: "Italie",
      HR: "Croatie",
      SG: "Singapour",
      CH: "Suisse",
    },

  },
  ABSENCE_TIME: {
    TITLE: "Absence",
    ABSENCE_START: "Arrêt du (date)",
    ABSENCE_END: "Arrêt jusqu’au (date)",
    MISSED_WORKING_HOURS: "Heures de travail perdues",
    EMAIL: "Email",
  },
  ACCIDENT_CAUSE: {
    TITLE: "Catégorie d’accident",
    INJURY_TYPE: "Nature des blessures",
    DESCRIPTION: "Description",
    BODYPARTS_TITLE: "Partie du corps blessée: ",
    CHOSEN_BODYPARTS: "Sélection des parties du corps:",
    TOOL_DEFECT: "Outils/appareils/machines électriques tenus en main",
    LOAD_MOVEMENT_BY_HAND: "Déplacement manuel d’une charge, chute/renversement d’objets",
    SHARP_OBJECTS: "Objets pointus et coupants, éclats",
    EYE_INJURIES: "Lésions oculaires si le globe oculaire est atteint, quelle qu’en soit la cause",
    HARMFUL_SUBSTANCES: "Substances et effets nocifs pour la santé",
    FALLS: "Faux pas, chutes",
    MACHINE_USE: "Machines de travail montées de manière fixe / installations électriques",
    MECHANICAL_TRANSPORT: "Moyens de transport mécaniques (avec/sans charges), engins mobiles, circulation routière",
    OTHER_CAUSE: "Autre causes",
    BITE: "Morsure",
    FRACTURES: "Fracture",
    INFLAMMATION: "Inflammation",
    FOREIGN_BODY: "Corps étranger",
    PROSPECTING: "Écorchure",
    BRUISE: "Contusion",
    CRACK: "Déchirure",
    CUT: "Coupure",
    GRAZING_SHOT: "Blessure par balle",
    SWELLING: "Tuméfaction",
    COMPRESSION_SPINE: "Entorse colonne",
    STING: "Picûre",
    SEPARATION: "Détachement, perte",
    CHEMICAL_BURN: "Brûlure par agent caustique",
    COMBUSTION: "Brûlure",
    SPRAIN: "Torsion, entorse",
    POISONING: "Intoxication",
    CONTORTION: "Luxation",
    STRAIN: "Élongation",
    OTHER_DAMAGE: "Autre lésion",
    FIRST_PRIORITY: "Blessure principale",
    SECOND_PRIORITY: "2ᵉ blessure",
    BODYPART_GROUP_LABEL: "Zone du corps concernée",
    BODYPARTS: {
      OTHER_INTERNAL_INJURY: "Autre lésions internes",
      EYE: "Oeil",
      ABDOMEN: "Ventre",
      PELVIS: "Bassin",
      BLADDER: "Vessie",
      THORACIC_SPINE: "Vertèbres thoraciques",
      ELBOW: "Coude",
      FINGER: "Doigts",
      ANKLE: "Cheville",
      WHOLE_BODY: "Tout le corps (effet systemiques)",
      GENITALS: "Parties génitales",
      FACE: "Visage",
      NECK: "Cou",
      CERVICAL_SPINE: "Colonne vertébrale cervicale",
      WRIST: "Poignet",
      HEART: "Coeur",
      HIP_JOINT: "Articulation de la hanche",
      JAW: "Mâchoire",
      KNEE: "Genou",
      LIVER: "Foie",
      GROIN: "Aine",
      LUMBAR_SPINE: "Vertèbres lombaires",
      LUNG: "Poumon",
      AREAS_UPPER_EXTREMITIES: "Plusieurs zones des extrémités supérieures",
      AREAS_LOVER_EXTREMITIES: "Plusieurs zones des extrémités intérieurs",
      MULTIPLE_INJURIES: "Polytraumatisé",
      SPLEEN: "Rate",
      METATARSUS: "Métatarse (sans orteils",
      METACARPUS: "Métatarse (soins les doigts orteils)",
      NOSE: "Nez",
      KIDNEY: "Rein",
      UPPER_ARM: "Bras",
      THIGH: "Cuisse",
      EAR_HEARING: "Oreille",
      BACK: "Dos",
      SKULL_BRAIN: "Crâne / cerveau",
      SHOCK: "choc (psychique)",
      SHOULDER: "Épaule",
      COCCYX_BUTTOCKS: "coccyx (fesses)",
      THORAX_RIBS_RIB_CAGE: "Thorax (côtes, cage thoracique)",
      FOREARM: "Avant-bras",
      LOWER_LEG: "Jambe",
      TEETH: "Dents",
      TOES: "Orteils",
      GROUP1: "Bassin, hanches, jambes (pas genou, pied)",
      GROUP2: "Doigt, main, poignet",
      GROUP3: "Peau, organes internes",
      GROUP4: "Genou, ménisque",
      GROUP5: "Tête (y compris yeux, ouïe, dents, gorge)",
      GROUP6: "Dos, colonne vertébrale",
      GROUP7: "Tronc, blessures multiples, autres",
      GROUP8: "Épaules, bras (pas main, poignet)",
      GROUP9: "Cheville, pied, orteils",
    },
    BODYPART_DESCRIPTION: "Description",
    OTHER_DESCRIPTION_TEXT:"Description détaillée du déroulement de l’accident (évolution, machines, installations, substances dangereuses impliquées)",
    OTHER_DESCRIPTION_TITLE:"Description détaillée",
    SIDE: "Côté",
    LEFT: "Gauche",
    RIGHT: "Droit",
    BOTH: "Au milieu, des deux côtés",
    UNDETERMINED: "Indéterminé",
  },
  VALIDATIONS: {
    REQUIRED_FIELD: "Ce champ est obligatoire!",
  },
  FIRMA: "Firma",
  INFO_DIALOG: {
    TITLE: "Les codes se rapportent à la cause d’un accident attribuable au début d’un enchaînement (c’est-à-dire la première cause à l’origine de l’accident).",
    FIRST_OPTION: {
      TITLE:"1 Outils/appareils/machines électriques tenus en main",
      LIST_ITEM_1: "Coupures/heurts/coincements dus à des outils/appareils, également lors de",
      LIST_ITEM_2: "Montage/démontage/travaux d’isolation/travaux de coffrage",
      LIST_ITEM_3: "Coupures dues à une pièce = code 3",
      LIST_ITEM_4: "Chute d’un marteau pendant le travail",
      LIST_ITEM_5: "Sollicitation excessive des mains/bras/épaules lors du montage ou d’autres travaux répétitifs avec des outils",

    },
    SECOND_OPTION: {
      TITLE:"2 Déplacement manuel d’une charge, chute/renversement d’objets",
      LIST_ITEM_1: "Notamment sollicitation excessive des mains/bras/épaules/dos lors de déplacements de charges",
      LIST_ITEM_2: "Heurts/coincements lors de travaux de rangement et de déplacement",
      LIST_ITEM_3: "Blessure lors de travaux de montage/coffrage non provoquée par un outil",
      LIST_ITEM_4: "Coupures/piqûres dues à des clous, fers d’armature, etc. = code 3",
    },
    THIRD_OPTION: {
      TITLE:"3 Objets pointus et coupants, éclats",
      LIST_ITEM_1: "Blessure provoquée par un outil manuel sans coupure/éclats = code 1, ou par une machine de travail = code 7",
      LIST_ITEM_2: "Clou enfoncé dans le pied en marchant dessus (ne relève pas du code 6)",
    },
    FOURTH_OPTION: {
      TITLE:"4 Lésions oculaires si le globe oculaire est atteint, quelle qu’en soit la cause",
    },
    FIFTH_OPTION: {
      TITLE:"5 Substances et effets nocifs pour la santé",
      LIST_ITEM_1: "Acides, alcalis, poisons, quartz/silicose, amiante, moisissures; asthme dû à des animaux de laboratoire, etc.",
      LIST_ITEM_2: "Substances provoquant de l’eczéma, agents pathogènes (amibes, palu-disme, etc.)",
      LIST_ITEM_3: "Influences physiques (froid, brûlures, bruit, vibrations, explosions, noyade)",
      LIST_ITEM_4: "Pression chronique sur les nerfs ou les articulations, p. ex. bursite de-vant la rotule chez les carreleurs",
    },
    SIXTH_OPTION: {
      TITLE:"6 Faux pas, chutes",
      LIST_ITEM_1: "Notamment glissades, chocs des orteils pendant la marche; blessures provoquées par des sauts",
      LIST_ITEM_2: "Entorse du pied/genou sans chute",
      LIST_ITEM_3: "Chute due à une crise d’épilepsie ou à une autre maladie",
      LIST_ITEM_4: "Chute de véhicules à l’arrêt, machines, échelles ou échafaudages, etc.",
      LIST_ITEM_5: "Blessures causées par le rattrapage",
    },
    SEVENTH_OPTION: {
      TITLE:"7 Machines de travail montées de manière fixe / installations électriques",
      LIST_ITEM_1: "Notamment coupures/ heurts/collisions/coincements lors de travaux sur des machines",
      LIST_ITEM_2: "Éclats/parties éjectées des machines, tuyaux sous pression, air compri-mé",
      LIST_ITEM_3: "Blessures dues au soudage, brûlure, électrocution",
    },
    EIGHT_OPTION: {
      TITLE:"8 Moyens de transport mécaniques (avec/sans charges), engins mobiles, circulation routière",
      LIST_ITEM_1: "Notamment heurts/collisions/coincements avec des chariots manuels, des chariots élévateurs, des camions, des grues, des ascenseurs, des portes d’ascenseur, des tapis roulants, des convoyeurs, des excava-teurs, des rouleaux",
      LIST_ITEM_2: "Chute de charges depuis les appareils susmentionnés",
      LIST_ITEM_3: "Accidents de la circulation, y compris tous les accidents impliquant des véhicules à deux roues, même sur le site de l’usine",
    },
    NINTH_OPTION: {
      TITLE:"9 Autres causes",
      LIST_ITEM_1: "Heurts/collisions sans activité particulière",
      LIST_ITEM_2: "Méfaits, disputes, vols, suicides",
      LIST_ITEM_3: "Blessures causées par des animaux/insectes",
      LIST_ITEM_4: "Sport (notamment blessures dans le vestiaire, sous la douche, etc.)",
      LIST_ITEM_5: "Tous les accidents à l’école professionnelle",
      LIST_ITEM_6: "Autres accidents non attribuables aux codes 1 à 8",
    },
  },
};
