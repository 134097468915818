import { IStarterAppConfiguration } from "../configuration";
import { BrowserCacheLocation, InteractionType } from "@azure/msal-browser";

export const P_ENV: IStarterAppConfiguration = {
  apiUrl: "https://api-incident.bkw.ch",
  azureConfig: {
    auth: {
      clientId: "63b73438-09c5-4e0c-92bf-dd4fd28e344d",
      authority: "https://login.microsoftonline.com/bkwfmbenergie.onmicrosoft.com",
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
    },
  },
  msalAngularInterceptorConfig: {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: [
      [
        "https://api-incident.bkw.ch/*",
        ["https://asgs-reporting-backend.aks14.int.bkw.ch/user_impersonation"],
      ],
    ] as any,
  },
  msalAngularGuardConfig: {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [],
    },
    loginFailedRoute: "./unauthorized",
  },
};
