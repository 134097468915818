import { DE_TRANSLATIONS } from "./de";
import { FR_TRANSLATIONS } from "./fr";
import { EN_TRANSLATIONS } from "./en";
import { IT_TRANSLATIONS } from "./it";

export const translations = {
  de: DE_TRANSLATIONS,
  fr: FR_TRANSLATIONS,
  en: EN_TRANSLATIONS,
  it: IT_TRANSLATIONS,
};
