import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { AccidentReport } from "../generated/asgs-reporting-backend-api/model/accidentReport";
import { Organisation } from "../generated/asgs-reporting-backend-api/model/organisation";

@Injectable()
export class AccidentReportService {

  constructor(
    private configurationService: BfcConfigurationService,
    private httpClient: HttpClient) {
  }

  createAccidentReport(report: AccidentReport): Observable<AccidentReport> {
    const url = `${this.configurationService.configuration.apiUrl}/accidentReports`;
    return this.httpClient.post<AccidentReport>(url, report);
  }

  updateAccidentReport(id: string, report: AccidentReport): Observable<AccidentReport> {
    const url = `${this.configurationService.configuration.apiUrl}/accidentReports/${id}`;
    return this.httpClient.put<AccidentReport>(url, report );
  }

  getOrganisations(competence?: string, country?: string): Observable<Organisation[]> {
    const url = `${this.configurationService.configuration.apiUrl}/organisations`;
    let params = new HttpParams();
    if (competence) {
      params = params.set("competence", competence);
    }
    if (country) {
      params = params.set("country", country);
    }
    return this.httpClient.get<Organisation[]>(url, { params });
  }

  checkDoubledEntry(checkObject): Observable<boolean> {
    const url = `${this.configurationService.configuration.apiUrl}/accidentReports/doubledEntryCheck`;
    return this.httpClient.post<boolean>(url, checkObject);
  }
}
