import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SuccessPageComponent } from "./success-page/success-page.component";

const routes: Routes = [
  {
    path: "accidentReport",
    canActivate: [MsalGuard],
    loadChildren: () => import("./accident-report/accident-report.module").then((m) => m.AccidentReportModule),
  },
  {
    path: "dashboard",
    canActivate: [MsalGuard],
    component: DashboardComponent,
  },
  {
    path: "success",
    component: SuccessPageComponent,
  },
  {
    path: "",
    canActivate: [MsalGuard],
    pathMatch: "full",
    redirectTo: "dashboard",
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
