export const IT_TRANSLATIONS = {
  REPORT_TITLE: "Tutela della salute e del lavoro",
  DONE_STEP: "Finito",
  BACK_TO_DASHBOARD: "Torna alla pagina iniziale",
  SUCCESS_MESSAGE: "I dati sono stati salvati con successo!",
  REPORT_CREATED_TEXT_1: "Grazie mille per aver inserito i dati!",
  REPORT_CREATED_TEXT_2: "Ora sono salvati e verranno analizzati dal servizio specializzato AS & GS.",
  REPORT_CREATED_TEXT_3: " In caso di domande, si prega di inviare un’e-mail al seguente indirizzo: <a href='mailto:asgs@bkw.ch'>asgs@bkw.ch</a>",
  DASHBOARD: {
    TITLE: "Registrazione di infortuni sul lavoro",
    WELCOME_TEXT: "Le diamo il benvenuto nel tool di registrazione degli infortuni sul lavoro.",
    INFO_TEXT: "Per migliorare la sicurezza sul lavoro è assolutamente necessario comprendere l’attuale situazione del Gruppo BKW e ricavarne delle misure da adottare. Gli indicatori sono uno strumento importante per l’analisi della situazione attuale. Questi dati vengono utilizzati per lavorare costantemente al miglioramento degli standard e delle procedure in materia di salute e sicurezza nonché a nuovi obiettivi.",
    INFO_TEXT_2: "Registrando i dati fornisce un contributo importante alla nostra cultura della sicurezza.",
    INFO_TEXT_3: " Dall’esperienza possiamo imparare e quindi apportare miglioramenti. Grazie mille",
    SIGNATURE: "Servizio specializzato per la sicurezza sul lavoro e tutela della salute",
  },
  BUTTONS: {
    NEXT: "Avanti",
    BACK: "Indietro",
    SAVE: "Risparmiare",
    CLOSE: "Chiudere",
    CANCEL: "Annulamento",
    ACCIDENT_REPORT: "Notifica un infortunio sul lavoro",
  },
  BASEDATA: {
    TITLE: "Dati di base",
    FIRST_NAME: "Nome",
    LAST_NAME: "Cognome",
    BIRTH_DATE: "Data di nascita",
    ACCIDENT_DATE: "Data dell'infortunio",
    ACCIDENT_LOCATION: "Località dell'infortunio",
    ACCIDENT_LOCATION_HINT: "Indicazione della località senza indirizzo.",
    ORGANISATION_HINT: "Geben Sie die Kompetenzfelder und das Land ein, um dieses Feld zu aktivieren",
    COUNTRY: "Paese",
    RADIO_BUTTON_TEXT: "Di che tipo di segnalazione si tratta?",
    COMPETENCES: "Ambiti di competenza",
    REPORTER_INFO: "Dati raccolti da:",
    ENERGY: "Energy",
    POWERGRID: "Power Grid",
    INFRASERVICE: "Infra-Services",
    BUILDING_SOLUTION: "Building Solutions",
    ENGINEERING: "Engineering",
    ENERGY_PRODUCTIONS: "Energy Productions",
    ENERGY_MARKETS: "Energy Markets",
    GROUP_SERVICES: "Group Services",
    DOUBLED_REPORT_INFO: "Attenzione: questi dati sono già stati registrati. Si prega di controllare.",
    INFO_TEXT_TYPE_3: ` <span> Definizione secondo l’assicurazione contro gli infortuni tedesca (Deutsche Gesetzliche Unfallversicherung - DGUV):</span>
                            <div>Gli infortuni in itinere sono infortuni subiti dai lavoratori nel tragitto verso o dal luogo di lavoro. Sono assicurate anche le deviazioni che si rendono necessarie, ad esempio:</div>
                            <ul>
                            <li>per accompagnare i bambini durante l’orario di lavoro</li>
                            <li>in caso di car-pooling</li>
                            <li>in caso di deviazioni</li>
                            <li>perché il posto di lavoro può essere raggiunto più velocemente seguendo un percorso più lungo</li>
                            </ul>
                             <div>Definizione ai sensi dello standard GRI 403:</div>
                             <div>Incidenti che si verificano nel tragitto verso il posto di lavoro solo se il trasporto è stato organizzato dall’organizzazione.</div>`,
    OCCUPATIONAL_ACCIDENT_LOST_DAYS: "Infortunio sul lavoro con giorni di assenza (LTI) Numero di infortuni sul lavoro con più di 8,2 ore",
    DEADLY_OCCUPATIONAL_ACCIDENT: "Infortunio sul lavoro mortale",
    ACCIDENT_ON_THE_WAY_TO_WORK: "Infortunio tragitto casa-lavoro",
    OCCUPATIONAL_ACCIDENT_WITHOUT_LOST_DAYS: "Infortunio sul lavoro senza giorni di assenza",
    NON_OCCUPATIONAL_ACCIDENT: "Infortunio non professionale",
    PROPERTY_DAMAGE: "Danni materiali",
    NEAR_ACCIDENT: "Mancato infortunio",
    TYPE_10: "Primo soccorso senza tempi di inattività",
    EMAIL: "e-mail",
    TYPE_OF_PERSON: {
      LABEL: "La persona infortunata è membro del:",
      INTERNAL_STAFF: "Personale proprio",
      EXTERNAL_STAFF: "Personale esterno",
    },
    COUNTRIES: {
      DE: "Germania",
      AT: "Austria",
      FR: "Francia",
      NO: "Norvegia",
      IT: "Italia",
      HR: "Croazia",
      SG: "Singapore",
      CH: "Svizzera",
    },

  },
  ABSENCE_TIME: {
    TITLE: "Periodo di assenza",
    ABSENCE_START: "Assenza dal giorno",
    ABSENCE_END: "Assenza fino al giorno",
    MISSED_WORKING_HOURS: "Ore di lavoro perse",
    EMAIL: "e-mail",
  },
  ACCIDENT_CAUSE: {
    TITLE: "Divisione Assicurazioni",
    INJURY_TYPE: "Tipo di lesione",
    DESCRIPTION: "Descrizione",
    BODYPARTS_TITLE: "Parti dei corpo ferite: ",
    CHOSEN_BODYPARTS: "Selezione delle parti del corpo:",
    TOOL_DEFECT: "Outils/appareils/machines électriques tenus en main",
    LOAD_MOVEMENT_BY_HAND: "Déplacement manuel d’une charge, chute/renversement d’objets",
    SHARP_OBJECTS: "Objets pointus et coupants, éclats",
    EYE_INJURIES: "Lésions oculaires si le globe oculaire est atteint, quelle qu’en soit la cause",
    HARMFUL_SUBSTANCES: "Substances et effets nocifs pour la santé",
    FALLS: "Faux pas, chutes",
    MACHINE_USE: "Machines de travail montées de manière fixe / installations électriques",
    MECHANICAL_TRANSPORT: "Moyens de transport mécaniques (avec/sans charges), engins mobiles, circulation routière",
    OTHER_CAUSE: "Autre causes",
    BITE: "Morso",
    FRACTURES: "Frattura",
    INFLAMMATION: "Inflammazione",
    FOREIGN_BODY: "Corpo estraneo",
    PROSPECTING: "Abrasione",
    BRUISE: "Contusione",
    CRACK: "Lacerazione",
    CUT: "Taglio",
    GRAZING_SHOT: "Ferita da ama da fuoco",
    SWELLING: "Gonfiore",
    COMPRESSION_SPINE: "Confusione assiale colonna vertebrale",
    STING: "Puntura",
    SEPARATION: "Distacco / Asportazione",
    CHEMICAL_BURN: "Causticazione",
    COMBUSTION: "Ustione",
    SPRAIN: "Torsion, entorse",
    POISONING: "Avvelenamento",
    CONTORTION: "Lussazione",
    STRAIN: "Stiramento",
    OTHER_DAMAGE: "Altra Lesione",
    FIRST_PRIORITY: "Grave infortunio",
    SECOND_PRIORITY: "2. Infortunio",
    BODYPART_GROUP_LABEL: "Regione del corpo interessata",
    BODYPARTS: {
      OTHER_INTERNAL_INJURY: "Altre lesioni interne",
      EYE: "Occhio",
      ABDOMEN: "Addome",
      PELVIS: "Bacino",
      BLADDER: "Vescica",
      THORACIC_SPINE: "Colonna vertebrale dorsale",
      ELBOW: "Gomito",
      FINGER: "Dita (mano)",
      ANKLE: "Caviglia",
      WHOLE_BODY: "Corpo intero (azione sistemica)",
      GENITALS: "Genitali",
      FACE: "Faccia",
      NECK: "Collo",
      CERVICAL_SPINE: "Colonna vertebrale cervicale",
      WRIST: "Polso",
      HEART: "Cuore",
      HIP_JOINT: "Anca",
      JAW: "Mascella/Mandibola",
      KNEE: "Ginocchio",
      LIVER: "Fegato",
      GROIN: "Inguine",
      LUMBAR_SPINE: "Colonna vertebrale lombare",
      LUNG: "Polmone",
      AREAS_UPPER_EXTREMITIES: "Diverse parti delle estremità superiori",
      AREAS_LOVER_EXTREMITIES: "Diverse parti delle estremità inferiori",
      MULTIPLE_INJURIES: "Politraumatizzato",
      SPLEEN: "Milza",
      METATARSUS: "Metatorso",
      METACARPUS: "Metacarpo",
      NOSE: "Naso",
      KIDNEY: "Rene",
      UPPER_ARM: "Braccio",
      THIGH: "Coscia",
      EAR_HEARING: "Orecchio",
      BACK: "Schiena",
      SKULL_BRAIN: "Cranio / Cervello",
      SHOCK: "Shock (psychico)",
      SHOULDER: "Spalla",
      COCCYX_BUTTOCKS: "Coccige (natiche)",
      THORAX_RIBS_RIB_CAGE: "Torace (coste, gabbia toracia)",
      FOREARM: "Avambraccio",
      LOWER_LEG: "Gambia",
      TEETH: "Denti",
      TOES: "Dita (piedi)",
      GROUP1: "Bacino, anca, gambe (senza ginocchio, piede)",
      GROUP2: "Dita (mano), mano, polso",
      GROUP3: "Pelle, organi interni",
      GROUP4: "Ginocchio, menisco",
      GROUP5: "Testa (compresi occhi, udito, denti, collo)",
      GROUP6: "Schiena, colonna vertebrale",
      GROUP7: "Tronc, blessures multiples, autres",
      GROUP8: "Spalla, braccia (senza mano, polso)",
      GROUP9: "..piede, dita dei piedi",
    },
    BODYPART_DESCRIPTION: "Descrizione",
    OTHER_DESCRIPTION_TEXT:"Descrizione dettagliata della dinamica dell’incidente (andamento, coinvolgimento di macchinari, impianti, sostanze pericolose)  ",
    OTHER_DESCRIPTION_TITLE:"Descrizione dettagliata",
    SIDE: "Lato",
    LEFT: "Sinistro",
    RIGHT: "Destro",
    BOTH: "Entrambi / centro",
    UNDETERMINED: "Indeterminato",
  },
  VALIDATIONS: {
    REQUIRED_FIELD: "Questo campo è obbligatorio!",
  },
  FIRMA: "Azienda",
  INFO_DIALOG: {
    TITLE: "Viene codificata la causa dell’infortunio che si trova all’inizio di una concatenazione di cause (ossia la prima causa scatenante dell’infortunio). ",
    FIRST_OPTION: {
      TITLE:"1 Attrezzi/apparecchi/macchine elettriche, quando tenuti in mano",
      LIST_ITEM_1: "Ferite da taglio/urti/schiacciamento mediante utensili/apparecchi anche in caso di ",
      LIST_ITEM_2: "montaggio/smontaggio/lavori di isolamento/lavori di cassaforma ",
      LIST_ITEM_3: "Ferite da taglio dovute al pezzo da lavorare = codice 3",
      LIST_ITEM_4: "Martello che cade durante il lavoro",
      LIST_ITEM_5: "Sovraffaticamento di mani/braccia/spalle durante il montaggio o altri lavori ripetitivi con utensili",

    },
    SECOND_OPTION: {
      TITLE:"2 Spostamento di un carico a mano, caduta (in basso/a valanga) di oggetti",
      LIST_ITEM_1: "incl. sovraffaticamento di mani/braccia/spalle/schiena durante movimenti di carichi",
      LIST_ITEM_2: "Urto/schiacciamento durante i lavori di spostamento/sgombero ",
      LIST_ITEM_3: "Durante i lavori di montaggio/cassaforma nessuna lesione da utensili",
      LIST_ITEM_4: "Ferite da taglio/punta causate da chiodi, ferri da armatura ecc. = codice 3",
    },
    THIRD_OPTION: {
      TITLE:"3 Oggetti appuntiti, taglienti, schegge",
      LIST_ITEM_1: "Nessuna ferita da taglio/scheggia causata da attrezzo = codice 1 o macchina da lavoro = codice 7",
      LIST_ITEM_2: "Chiodo calpestato con il piede (non codice 6)",
    },
    FOURTH_OPTION: {
      TITLE:"4 Lesioni agli occhi, se è coinvolto il bulbo oculare, indipendentemente dalla causa",
    },
    FIFTH_OPTION: {
      TITLE:"5 Sostanze ed effetti nocivi per la salute",
      LIST_ITEM_1:  "Acidi, alcali, veleni, silicosi, amianto, farina; asma causata da animali da laboratorio, ecc.",
      LIST_ITEM_2: "Sostanze che causano eczema, agenti patogeni (ameba, malaria, ecc.)",
      LIST_ITEM_3: "Effetti fisici (freddo, ustioni, rumore, vibrazioni, esplosioni, annegamento)",
      LIST_ITEM_4:  "Compressione cronica di nervi o articolazioni, ad es. borsite pre-rotulea per i piastrellisti",
    },
    SIXTH_OPTION: {
      TITLE:"6 Passi falsi, cadute",
      LIST_ITEM_1: "incl. scivolamento, urto delle dita dei piedi camminando; lesioni causate da salti",
      LIST_ITEM_2: "Storta al piede/ginocchio senza caduta",
      LIST_ITEM_3: "Caduta dovuta a epilessia o ad altre malattie",
      LIST_ITEM_4: "Caduta da veicoli fermi, macchinari, scale o ponteggi ecc.",
      LIST_ITEM_5: "Lesioni causate da una manovra di salvataggio",
    },
    SEVENTH_OPTION: {
      TITLE:"7 Macchine da lavoro, se montate saldamente / impianti elettrici ",
      LIST_ITEM_1: "incl. taglio/urto/colpo/schiacciamento durante il lavoro ai macchinari ",
      LIST_ITEM_2: "Frammenti/parti volanti di macchine, tubi flessibili sotto pressione, aria compressa ",
      LIST_ITEM_3: "Lesioni dovute a saldatura, fiammatura, folgorazione ",
    },
    EIGHT_OPTION: {
      TITLE:"8 Mezzi di trasporto meccanici (con/senza carichi), attrezzi mobili, traffico stradale ",
      LIST_ITEM_1: "incl. urto/colpo/schiacciamento con carrelli manuali, muletti, autocarri, gru, ascensori, porte degli ascensori, nastri trasportatori, impianti trasportatori, escavatori, rulli ",
      LIST_ITEM_2: "Caduta del carico dalle macchine summenzionate ",
      LIST_ITEM_3:  "Incidenti stradali incl. tutti gli incidenti con veicoli a due ruote, anche nell’area dello stabilimento ",
    },
    NINTH_OPTION: {
      TITLE:" 9 Altre cause",
      LIST_ITEM_1: "Urti/colpi dovuti a nessuna attività in particolare ",
      LIST_ITEM_2: "Distrazione, litigi, ladri, suicidio ",
      LIST_ITEM_3: "Lesioni causate da animali/insetti ",
      LIST_ITEM_4: "Sport (incl. lesioni nello spogliatoio, nella doccia ecc.) ",
      LIST_ITEM_5: "Tutti gli infortuni verificatisi nella scuola professionale ",
      LIST_ITEM_6: "Altri infortuni non attribuibili ai punti 1-8 ",
    },
  },
};
